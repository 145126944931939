/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  FORGOT_PASSWORD = '/forgot-password',
  LOGIN = '/login',
  NOT_FOUND = '/not-found',
  RESET_PASSWORD = '/reset-password',
  SIGN_UP = '/register',
  EMPTY = '/',
  ACCOUNT = '/accounts',
  NEW = '/new',
  EDIT = '/edit',
  ID = '/:id',
  REPAIR_ID = '/:repairId',
  DOCUMENT_ID = '/:documentId',
  REPAIR_PATH = '/repairs',
  INSURANCE_APPLICATION = '/submit-agreement',
  REQUEST = '/request',
  HASH_CODE = '/:hashCode',
  CUSTOMERS = '/customers',
  CLIENTS = '/clients',
  DOCUMENT_VIEWER = '/document-viewer',
  PERSONAL_INFO = '/personal-info',
  ADMIN = '/admin',
  CUSTOMER = '/customer',
  REPAIRS = '/admin/repairs',
  REPAIRS_CREATE = '/admin/repairs/new',
  REPAIRS_EDIT = '/admin/repairs/edit/:id',
  REPAIRS_DETAIL = '/admin/repairs/:id',
  MAKERS = '/makers',
  PRODUCTS = '/products',
  MODELS = '/models',
  CUSTOMER_HISTORY = '/customer-history',
  CONFIG = '/config',
  EXPIRATION = '/expiration',
  SUCCESS = '/success',
  DETAIL = '/detail',
  IMEI = '/imei'
}

export enum ScreenType {
  CREATE = 'create',
  EDIT = 'edit',
  DETAIL = 'detail'
}
