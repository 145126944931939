import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import UserNotLogin from 'src/layouts/UserNotLogin';
import CustomerLayout from 'src/layouts/Customer';
import { AuthRole } from 'src/enums/auth';
import ConfigController from 'src/pages/admin/Config/components/controller';
import { PermissionList } from 'src/enums/permission';

const ConfigPage = import('../pages/admin/Config');
const ExpirationPage = import('../pages/admin/Expiration');
const AdminLoginPage = import('../pages/admin/Login');
const CustomerLoginPage = import('../pages/customer/Login');
const AdminInsuranceApplicationCreate = import(
  '../pages/admin/SubmitAgreement/create'
);
const AdminInsuranceApplicationEdit = import(
  '../pages/admin/SubmitAgreement/edit'
);
const AdminInsuranceApplication = import('../pages/admin/SubmitAgreement');
const AdminAccount = import('../pages/admin/Account');
const AdminCreateAccount = import('../pages/admin/Account/create');
const AdminCustomerList = import('../pages/admin/Customers');
const AdminDashboard = import('../pages/admin/Dashboard');
const AdminEditAccount = import('../pages/admin/Account/edit');
const AdminAccountDetail = import('../pages/admin/Account/detail');
const AdminCreateCustomer = import('../pages/admin/Customers/create');
const AdminEditCustomer = import('../pages/admin/Customers/edit');
const AdminDetailCustomer = import('../pages/admin/Customers/detail');
const ForgotPasswordPage = import('../pages/customer/ForgotPassword');
const ResetPasswordPage = import('../pages/customer/ResetPassword');
const CustomerAgreementDetailPage = import(
  '../pages/customer/InsuranceApplicationConfirm/logged-in-pages/AgreementDetail'
);
const PublicInsuranceConfirmPendingPage = import(
  '../pages/customer/InsuranceApplicationConfirm/public-pages/confirm-pending'
);
const PublicInsuranceConfirmSuccessPage = import(
  '../pages/customer/InsuranceApplicationConfirm/public-pages/confirm-success'
);
const DocumentViewerPage = import(
  '../pages/customer/InsuranceApplicationConfirm/components/DocumentViewer'
);
const CustomerAgreementListPage = import(
  '../pages/customer/InsuranceApplicationConfirm/logged-in-pages/AgreementList'
);
const CustomerImeiListPage = import(
  '../pages/customer/Imei/logged-in-pages/ImeiList'
);
const AdminRepairList = import('../pages/admin/Repairs');
const RepairCreatePage = import('../pages/admin/Repairs/create');
const RepairEditPage = import('../pages/admin/Repairs/edit');
const RepairDetailPage = import('../pages/admin/Repairs/detail');
const MakerList = import('../pages/admin/Makers');
const ProductCategoryList = import('../pages/admin/ProductCategory');
const ModelList = import('../pages/admin/Models');
const CustomerHistoryListPage = import(
  '../pages/customer/InsuranceApplicationConfirm/logged-in-pages/History/HistoryList'
);
const CustomerHistoryDetailPage = import(
  '../pages/customer/InsuranceApplicationConfirm/logged-in-pages/History/HistoryDetail'
);
const CustomerPersonalInfoPage = import('../pages/customer/PersonalInfo');
const AdminClientList = import('../pages/admin/Clients');
const AdminCreateClientPage = import('../pages/admin/Clients/create');
const AdminEditClientPage = import('../pages/admin/Clients/edit');
const AdminClientDetailPage = import('../pages/admin/Clients/detail');
const ImeiCreatePage = import('../pages/admin/Imei/create');

export const routes: RouteI[] = [
  {
    path: RoutePath.ADMIN + RoutePath.LOGIN,
    Component: lazy(() => AdminLoginPage)
  },
  {
    path: RoutePath.EMPTY,
    Component: lazy(() => AdminDashboard),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN,
    Component: lazy(() => AdminDashboard),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.FORGOT_PASSWORD,
    Component: lazy(() => ForgotPasswordPage)
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage)
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT,
    Component: lazy(() => AdminAccount),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.ACCOUNT_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT + RoutePath.NEW,
    Component: lazy(() => AdminCreateAccount),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.ACCOUNT_CREATE,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS + RoutePath.NEW,
    Component: lazy(() => AdminCreateCustomer),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CUSTOMER_CREATE,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS + RoutePath.ID + RoutePath.EDIT,
    Component: lazy(() => AdminEditCustomer),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CUSTOMER_EDIT,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS + RoutePath.ID,
    Component: lazy(() => AdminDetailCustomer),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CUSTOMER_DETAIL,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT + RoutePath.ID,
    Component: lazy(() => AdminAccountDetail),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.ACCOUNT_DETAIL,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT + RoutePath.ID + RoutePath.EDIT,
    Component: lazy(() => AdminEditAccount),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.ACCOUNT_EDIT,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION + RoutePath.NEW,
    Component: lazy(() => AdminInsuranceApplicationCreate),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.SUBMIT_AGREEMENT_CREATE,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION,
    Component: lazy(() => AdminInsuranceApplication),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.SUBMIT_AGREEMENT_LIST,
    role: AuthRole.ADMIN
  },
  {
    path:
      RoutePath.ADMIN +
      RoutePath.INSURANCE_APPLICATION +
      RoutePath.ID +
      RoutePath.EDIT,
    Component: lazy(() => AdminInsuranceApplicationEdit),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.SUBMIT_AGREEMENT_EDIT,
    role: AuthRole.ADMIN
  },
  {
    path:
      RoutePath.CUSTOMER +
      RoutePath.INSURANCE_APPLICATION +
      RoutePath.HASH_CODE,
    isPrivate: true,
    Layout: <CustomerLayout />,
    Component: lazy(() => CustomerAgreementDetailPage),
    role: AuthRole.CUSTOMER
  },
  {
    path: RoutePath.REQUEST + RoutePath.HASH_CODE,
    Layout: <UserNotLogin />,
    Component: lazy(() => PublicInsuranceConfirmPendingPage)
  },
  {
    path: RoutePath.REQUEST + RoutePath.ID + RoutePath.SUCCESS,
    Component: lazy(() => PublicInsuranceConfirmSuccessPage)
  },
  {
    path:
      RoutePath.ADMIN +
      RoutePath.REPAIR_PATH +
      RoutePath.REPAIR_ID +
      RoutePath.DOCUMENT_VIEWER +
      RoutePath.DOCUMENT_ID,
    Layout: <UserNotLogin />,
    Component: lazy(() => DocumentViewerPage)
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS,
    Component: lazy(() => AdminCustomerList),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CUSTOMER_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.LOGIN,
    Component: lazy(() => CustomerLoginPage)
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.INSURANCE_APPLICATION,
    Component: lazy(() => CustomerAgreementListPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.IMEI,
    Component: lazy(() => CustomerImeiListPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.CUSTOMER_HISTORY,
    Component: lazy(() => CustomerHistoryListPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.CUSTOMER_HISTORY + RoutePath.ID,
    Component: lazy(() => CustomerHistoryDetailPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.PERSONAL_INFO,
    Component: lazy(() => CustomerPersonalInfoPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.REPAIRS,
    Component: lazy(() => AdminRepairList),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.REPAIR_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.REPAIRS_CREATE,
    Component: lazy(() => RepairCreatePage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.REPAIR_CREATE,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.REPAIRS_EDIT,
    Component: lazy(() => RepairEditPage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.REPAIR_EDIT,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.REPAIRS_DETAIL,
    Component: lazy(() => RepairDetailPage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.REPAIR_DETAIL,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.MAKERS,
    Component: lazy(() => MakerList),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.MAKER_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.PRODUCTS,
    Component: lazy(() => ProductCategoryList),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CATEGORY_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.MODELS,
    Component: lazy(() => ModelList),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.MODEL_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CONFIG,
    Component: lazy(() => ConfigPage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.PERMISSION_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CONFIG + RoutePath.EXPIRATION,
    Component: lazy(() => ExpirationPage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.ENABLING_REPAIR_AGREEMENT_LINK,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CONFIG + RoutePath.NEW,
    Component: ConfigController,
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.PERMISSION_CREATE,
    role: AuthRole.ADMIN
  },

  {
    path: RoutePath.ADMIN + RoutePath.CONFIG + RoutePath.ID,
    Component: ConfigController,
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.PERMISSION_EDIT,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CLIENTS,
    Component: lazy(() => AdminClientList),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CLIENT_LIST,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CLIENTS + RoutePath.NEW,
    Component: lazy(() => AdminCreateClientPage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CLIENT_CREATE,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CLIENTS + RoutePath.ID + RoutePath.EDIT,
    Component: lazy(() => AdminEditClientPage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CLIENT_EDIT,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CLIENTS + RoutePath.ID,
    Component: lazy(() => AdminClientDetailPage),
    Layout: <MainLayout />,
    isPrivate: true,
    requirePermission: PermissionList.CLIENT_DETAIL,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.IMEI + RoutePath.NEW,
    Component: lazy(() => ImeiCreatePage),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  }
];
