import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, ja_JP } from 'core-ui';
import theme from './theme';
import { Helmet } from 'react-helmet-async';
import { pdfjs } from 'react-pdf';
import ProfileProvider from './contexts/profile';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content={process.env.REACT_APP_ROBOT_CONTENT || 'noindex,nofollow'}
        />
      </Helmet>
      <ProfileProvider>
        <ConfigProvider
          locale={ja_JP}
          theme={theme}
          button={{ autoInsertSpace: false }}
          form={{
            scrollToFirstError: { block: 'center' },
            validateMessages: {
              required: '${label}は必須です。',
              types: {
                email: '${label}は無効です。'
              },
              string: { max: '${max}文字以内で入力してください。' },
              whitespace: '${label}は必須です。'
            }
          }}
        >
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <ModalProvider>
                  <DrawerProvider>
                    <AppRouter />
                  </DrawerProvider>
                </ModalProvider>
              </Provider>
            </QueryClientProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ProfileProvider>
    </>
  );
};

export default App;
