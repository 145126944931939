import MenuIconWrapper from 'src/components/MenuIconWrapper';

const AddIcon = () => {
  return (
    <MenuIconWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 30 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5537 13.4638C13.5537 13.3071 13.4292 13.1786 13.2725 13.1786H11.585C11.4323 13.1786 11.3037 13.3071 11.3037 13.4638V16.875H7.90864C7.74391 16.875 7.6073 16.9996 7.6073 17.1562V18.8438C7.6073 18.9964 7.74391 19.125 7.90864 19.125H11.3037V22.5362C11.3037 22.6929 11.4323 22.8214 11.585 22.8214H13.2725C13.4292 22.8214 13.5537 22.6929 13.5537 22.5362V19.125H16.9488C17.1136 19.125 17.2502 18.9964 17.2502 18.8438V17.1562C17.2502 16.9996 17.1136 16.875 16.9488 16.875H13.5537V13.4638ZM16.9488 25.7143H7.90864C7.74391 25.7143 7.6073 25.8429 7.6073 25.9955V27.683C7.6073 27.8357 7.74391 27.9643 7.90864 27.9643H16.9488C17.1136 27.9643 17.2502 27.8357 17.2502 27.683V25.9955C17.2502 25.8429 17.1136 25.7143 16.9488 25.7143ZM16.6636 5.52054C16.4225 5.27946 16.097 5.14286 15.7555 5.14286H2.14301C1.43185 5.14286 0.8573 5.71741 0.8573 6.42857V34.7143C0.8573 35.4254 1.43185 36 2.14301 36H22.7144C23.4256 36 24.0002 35.4254 24.0002 34.7143V13.3915C24.0002 13.05 23.8636 12.7246 23.6225 12.4835L16.6636 5.52054ZM21.1073 33.1071H3.75016V8.03571H15.0886L21.1073 14.0545V33.1071ZM28.7493 9.74732L18.9899 0.357589C18.7488 0.128571 18.4314 0 18.0979 0H6.32159C6.1448 0 6.00016 0.144643 6.00016 0.321429V2.57143C6.00016 2.74821 6.1448 2.89286 6.32159 2.89286H17.4511L26.2502 11.3545V30.5357C26.2502 30.7125 26.3948 30.8571 26.5716 30.8571H28.8216C28.9984 30.8571 29.143 30.7125 29.143 30.5357V10.6714C29.143 10.3219 29.0024 9.98839 28.7493 9.74732Z"
          fill="black"
          fill-opacity="0.38"
        />
      </svg>
    </MenuIconWrapper>
  );
};

export default AddIcon;
